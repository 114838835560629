<script>

  import {getUrlPrefixToShow} from "@/helpers/functions";
  import {ADMIN_, COMMERCIAL_, COORDINATOR_, SUPERVISOR_} from "@/helpers/constant/roles";
  import {inject} from "vue";
  import {SUPER_ADMIN_} from "../../../helpers/constant/roles";

  export default {
    name: 'account-table-card',
    components: {},
    props: {
      accounts: {
        type: Array,
        default(){
         return []
        }
      },
      role: {
        type: String,
        default: 'supervisor'
      },
      pageName: {
        type: String,
        default: null
      }
    },
    data(){
      return {
      }
    },
    setup(){
      const globals = inject("globals");
      return {globals}
    },
    methods: {
      getUrlPrefixToShow,
      getAge(birthDate){
        const currentYear = new Date().getFullYear()
        const birthYear =  Number(birthDate.split('-')[0])

        return  currentYear - birthYear
      },
      getCreatedAndUpdatedDates(date){
        return date.split('T')[0]
      },
      openUpdateUserModal(user){
        this.$emit('openUpdateUserModal', user)
      },
      formatNumber(num) {
        if (num >= 1000000) {
          this.unit = 'M'
          return Math.ceil(num / 1000000);
        } else if (num >= 100000) {
          this.unit = 'K'
          return Math.ceil(num / 1000);
        } else {
          return num;
        }
      },
      formatPhoneNumber(phoneNumber){
        return phoneNumber.split('237')[1]
      }
    },
    computed: {
      getDetailPrefixUrl() {
        let role = ""
        if (this.globals.roles.IS_SUPER_ADMIN){
          role = SUPER_ADMIN_
        } else if (this.globals.roles.IS_ADMIN) {
          role = ADMIN_
        } else if (this.globals.roles.IS_SUPERVISOR) {
          role = SUPERVISOR_
        } else if (this.globals.roles.IS_COORDINATOR) {
          role = COORDINATOR_
        } else {
          role = COMMERCIAL_
        }

        return this.getUrlPrefixToShow(role, "account")
      }
    }
  }
</script>

<template>
    <div class="table-responsive">
      <table class="table table-hover table-striped align-middle table-nowrap mb-0">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nom</th>
          <!-- <th scope="col" >Inscription</th> -->
          <th scope="col" >Epargne totale</th>
          <th scope="col" class="">Inscription</th>
          <th scope="col">Titulaire</th>
          <th scope="col">Téléphone</th>
          <th scope="col">Identifiant</th>
          <th scope="col">Créé le</th>
           <th scope="col">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(a, index) in accounts" :key="index">
          <th scope="row">{{index + 1}}</th>
          <th >{{a.name.split(' ')[0]}}</th>
          <td>{{ formatNumber(a.savings) }} XAF</td>
          <td class="text-center">
            <div class="hstack gap-3 fs-15">
              <i v-if="a.accountHasActiveSubscription" class="ri-checkbox-circle-line text-success"></i>
              <i v-else class="ri-close-circle-line text-danger"></i>
            </div>
          </td>
          <td>{{ a.user?.lastname || '' }} {{ a.user?.firstname || '' }} </td>
          <td>{{ a.user?.phoneNumber }}</td>
          <td>{{ a.identifier }}</td>
          <td>{{ getCreatedAndUpdatedDates(a.createdAt) }}</td>
          <!-- <td v-if="pageName === 'admin'">
            <div class="form-check form-switch">
              <input class="form-check-input" @change="updateStatus(user.id, user.status)" type="checkbox" role="switch" :id="'SwitchCheck'+ index" :checked="user.status">
            </div>
          </td> -->
<!--              <td>{{ user.updatedAt && getCreatedAndUpdatedDates(user.updatedAt) }}</td>-->
          <td>
            <div class="hstack gap-3 fs-15">
                <router-link :to="getDetailPrefixUrl + a.uniqId" class="link-primary"><i class="ri-eye-fill"></i></router-link>
<!--                <a href="javascript:void(0);" @click="openUpdateUserModal(user)" class="link-primary"><i class="ri-settings-4-line"></i></a>-->
<!--                <a href="javascript:void(0);" class="link-danger"><i class="ri-delete-bin-5-line"></i></a>-->
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
</template>

<style scoped>

</style>